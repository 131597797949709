<template>
  <q-form ref="editForm">
    <c-card title="감사실행정보" class="cardClassDetailForm" topClass="topcolor-lightblue">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-show="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="planData"
            :mappingType="mappingType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveData"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="감사원 소견"
            name="selfInspectionResultOpinion"
            v-model="planData.selfInspectionResultOpinion"
            :rows="5">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="감사 결과 종합의견"
            name="selfInspectionResultTotalOpinion"
            v-model="planData.selfInspectionResultTotalOpinion"
            :rows="5">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="시정 및 권고 요약"
            name="selfInspectionResultCorrect"
            v-model="planData.selfInspectionResultCorrect"
            :rows="5">
          </c-textarea>
        </div>
      </template>
    </c-card>
  </q-form>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'run-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
      }),
    },
    planData: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',  // 자체감사 계획 일련번호
        selfInspectionTitle: '',  // 감사명
        selfInspectionYear: '',  // 감사년도
        selfInspectionTypeCd: null,  // 감사종류
        plantCd: null,  // 해당사업장
        remark: '',  // 감사대상 개요
        selfInspectionStartDt: '',  // 감사기간-시작일
        selfInspectionEndDt: '',  // 감사기간-종료일
        selfInspectionStatusCd: '',  // 감사진행상태-계획중,계획완료,감사완료
        selfInspectionResultOpinion: '',  // 감사원소견
        selfInspectionResultTotalOpinion: '',  // 감사 결과 종합의견
        selfInspectionResultCorrect: '',  // 시정 및 권고 요약
        selfInspectionDt: [],
        sysRevision: '',  // 평가항목 개정번호
        chgUserId: '',
        sysApprovalRequestId: '',
        approvalTypeCd: '',
        approvalStatusCd: '',
        approvalStatusName: '',
        processList: [],
        equipList: [],
        deptList: [],
        execList: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      updateUrl: '',
      isSave: false,
      mappingType: 'PUT',
      saveUrl: transactionConfig.sop.pca.result.info.update.url,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.param.selfInspectionStatusCd != 'SISC000002' || this.planData.approvalStatusCd === 'ASC0000001';
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.updateUrl = transactionConfig.sop.pca.result.info.update.url;
    },
    saveData() {
      this.saveUrl = this.updateUrl;
      this.mappingType = 'PUT';
      let saveMsg = 'MSGSAVE';
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.planData.regUserId = this.$store.getters.user.userId;
              this.planData.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
  }
};
</script>
